/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.print-body {
  padding: 40px;
}

.print-only {
  display: none !important;
}

@media print {
  body .seqohs-form {
    zoom: 0.66;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @page {
    margin: 32px;
  }

  .reference-feedback-print {
    /* padding: 24px !important; */
    zoom: 0.9;
  }

  .print-break {
    page-break-after: always;
  }

  /* .print-only {
    display: table-row !important;
  } */

  .non-print-only {
    display: none !important;
  }
}
